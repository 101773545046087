import React, { PureComponent } from 'react'
import { Layout, SEO, Billboard, Button } from '@renderbus/common/components'
import { graphql } from 'gatsby'
import LightImg from '../images/xgt-membership-month/light.png'
import BoxImg from '../images/banner/xgt-membership-month/box.png'
import TitleImg from '../images/banner/xgt-membership-month/title.png'
import TitleMbImg from '../images/banner/xgt-membership-month/title-mb.png'
import BadgeImg from '../images/xgt-membership-month/badge.png'
import Step1 from '../images/xgt-membership-month/step1.png'
import Step2 from '../images/xgt-membership-month/step2.png'
import Step3 from '../images/xgt-membership-month/step3.png'
import Step4 from '../images/xgt-membership-month/step4.png'

import {
  BannerContainer,
  BannerWrapper,
  Light,
  TitleWrapper,
  Title,
  TitleMb,
  Box,
  Wrapper,
  Section,
  Badge,
  SectionTitle,
  Tip,
  TipWrapper,
  StepContainer,
  Step,
  Description,
  Coupon,
  Left,
  Divider,
  Right,
} from './xgt-membership-month.atom'

const SectionWrapper = props => (
  <Section>
    <Light src={LightImg} alt='' />
    {props.children}
  </Section>
)
class XGTMembershipMonth extends PureComponent {
  handleClick(href) {
    window.location.href = href
  }
  render() {
    const {
      location,
      data,
      pageContext: { topThreeShare },
    } = this.props
    return (
      <Layout
        location={location}
        topThreeShare={topThreeShare}
        style={{ backgroundColor: '#110F0F' }}
      >
        <SEO
          title='瑞云效果图会员月充值活动 - 瑞云渲染'
          keywords='效果图充值活动,效果图会员活动,瑞云充值活动'
          description='Renderbus瑞云渲染效果图会员月充值,限时加赠,会员享受五折渲染+送免单券,购买半年效果图会员赠送一个月会员,年度效果图会员赠送两个月会员！'
        />
        <BannerContainer>
          <BannerWrapper>
            <TitleWrapper>
              <Title src={TitleImg} alt='效果图会员月 限时加赠 会员享受五折渲染+送免单券' />
              <TitleMb src={TitleMbImg} alt='效果图会员月 限时加赠 会员享受五折渲染+送免单券' />
              <Button
                id='hyyhdy-ljgm-ban'
                onMouseDown={() => this.handleClick('https://task.renderbus.com/center/user/topUp')}
              >
                立即购买
              </Button>
            </TitleWrapper>
            <Box src={BoxImg} alt=''></Box>
          </BannerWrapper>
          <Billboard
            fluid={[
              data.bannerMb.childImageSharp.fluid,
              {
                ...data.banner.childImageSharp.fluid,
                media: `(min-width: 780px)`,
              },
            ]}
            alt='瑞云效果图会员月充值活动'
          />
        </BannerContainer>
        <Wrapper>
          <SectionWrapper>
            <Badge src={BadgeImg} alt='效果图专享' />
            <SectionTitle>会员权益</SectionTitle>
            <Tip>活动时间：2021.4.15—5.15</Tip>
            <StepContainer>
              <Coupon>
                <Left>送1个月会员</Left>
                <Divider />
                <Right>
                  <p>购买半年会员</p>
                  <Button
                    id='hyyhdy-ljgm-bn'
                    onClick={() =>
                      this.handleClick('https://task.renderbus.com/center/user/topUp?type=6')
                    }
                  >
                    立即购买
                  </Button>
                </Right>
              </Coupon>
              <Coupon>
                <Left>送2个月会员</Left>
                <Divider />
                <Right>
                  <p>购买一年会员</p>
                  <Button
                    id='hyyhdy-ljgm-yn'
                    onClick={() =>
                      this.handleClick('https://task.renderbus.com/center/user/topUp?type=2')
                    }
                  >
                    立即购买
                  </Button>
                </Right>
              </Coupon>
            </StepContainer>
            <TipWrapper>
              <p>*会员每月赠送4张免单券（不限金额）</p>
              <p>*会员期间渲染享受5折优惠</p>
              <p>*累计充值每满1000元额外赠送1张免单券（每月封顶10张）</p>
            </TipWrapper>
          </SectionWrapper>
          <SectionWrapper>
            <SectionTitle>参与方式</SectionTitle>
            <StepContainer>
              <Step src={Step1} alt='登录账号' />
              <Step src={Step2} alt='购买会员' />
              <Step src={Step3} alt='会员到账' />
              <Step src={Step4} alt='咨询客服' />
            </StepContainer>
          </SectionWrapper>
          <SectionWrapper>
            <SectionTitle>活动规则</SectionTitle>
            <Description>
              <p>1.购买半年会员赠送1个月会员，年度会员赠送2个月会员；</p>
              <p>2.用户购买后，赠送会员直接到账（每个账号仅限1次购买机会）；</p>
              <p>3.会员购买后不予退款；</p>
              <p>4.如有任何问题，可以联系瑞云客服，此次活动数量有限，先到先得；</p>
              <p>
                5.本次活动的最终解释权归瑞云科技所有，瑞云有权对恶意刷抢活动资源，利用资源从事违法违规行为的用户收回活动参与资格，并进行封号处理。
              </p>
            </Description>
          </SectionWrapper>
        </Wrapper>
      </Layout>
    )
  }
}
export const query = graphql`
  query {
    banner: file(relativePath: { regex: "/xgt-membership-month/banner.png/" }) {
      ...fluidImage
    }
    bannerMb: file(relativePath: { regex: "/banner/xgt-membership-month/background-mb.png/" }) {
      ...fluidImage
    }
  }
`
export default XGTMembershipMonth
